export const featureFlags = {
  INSTANT_REFUNDS: 'instant-refunds',
  RECHARGE_ENABLED: 'recharge-refunds',
  SMART_RECOMMENDATIONS: 'smart-recommendations',
  STATIC_WORKFLOW_TEMPLATES: 'static-workflow-templates',
  STATIC_WORKFLOW_TEMPLATES_RETURNLY_TAGS: 'static-workflow-templates-returnly-tags',
  WORKFLOWS_EXTERNAL_CALLS: 'workflow-external-calls',
  PACKING_SLIP_TRANSLATION: 'packing-slip-translation',
  SHOP_SCOPED_ROLES_ENABLED: 'shop-scoped-roles-enabled',
  SHOP_CASH: 'shop-cash',
  REJECT_ITEM_EDIT_RETURN_ENABLED: 'reject-item-edit-return-enabled',
  WORKFLOWS_TESTBENCH: 'workflows-testbench',
  DELIVERY_RETURN_WINDOW: 'delivery-return-window',
  TAX_INCLUSIVE_PACKING_SLIP_PRICES: 'tax-inclusive-packing-slip-prices',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  AUTH0_SESSIONS_ENABLED: 'auth0-sessions-enabled',
  REJECT_RETURN_V2: 'reject-return-v2',
  RETURN_REFUND_SERVICE_ENABLED: 'return-refund-service-enabled',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  MULTI_LABELS_ALLOWED: 'multi-labels-allowed',
  PANDIUM_LEGACY_ONLY: 'pandium-legacy-only',
  PARTIAL_IX_REFUNDS: 'partial-ix-refunds',
  PER_PRODUCT_HANDLING_FEES: 'per-product-handling-fees',
  OFFSET_INTERNATIONAL_RETURN_POLICIES: 'offset-international-return-policies',
  CUSTOMIZED_FULFILLABLE_LOCATIONS: 'customized-fulfillable-locations',
  EMBEDDED_PORTAL: 'embedded-portal',
  SHIPPING_PROTECTION: 'shipping-protection',
  GOODDATA_ANALYTICS: 'good-data-analytics',
  OFFSET_HANDLING_FEE_EXCEPTIONS: 'offset-handling-fee-exceptions',
};
